import { getPageInformation } from "@/app/api/admin";
import {
  getCandidateScreenings,
  getScreeningsByLocation,
} from "@/app/api/screeners";
import type { DataDumpResponse } from "@/types/api";
import { ExtensionAcceptedMessageTypes } from "@/types/extension";

const postMessageToExtension = <
  T extends { type: ExtensionAcceptedMessageTypes },
>(
  body: T,
) => parent.postMessage(body, "*");

const initializeAdmin = async ({ atsDomain }: { atsDomain: string }) => {
  postMessageToExtension({
    atsDomain: atsDomain,
    type: ExtensionAcceptedMessageTypes.AdminInitialized,
  });
};

const sendJobPageData = async (pageInformation: DataDumpResponse) => {
  const screenerLocationId = pageInformation.screenerLocationIds[0];

  if (!screenerLocationId) {
    postMessageToExtension({
      iconText: null,
      pageWidth: "full",
      type: ExtensionAcceptedMessageTypes.AdminStateChange,
    });
    return;
  }

  const screenerLocation = await getScreeningsByLocation(screenerLocationId);

  postMessageToExtension({
    iconText: `View ${screenerLocation.screenerSessions.length} screened candidates for this position!`,
    pageWidth: "full",
    type: ExtensionAcceptedMessageTypes.AdminStateChange,
  });
};

const sendCandidatePageData = async (pageInformation: DataDumpResponse) => {
  const { candidateEmails } = pageInformation;
  const candidateEmail = candidateEmails[0];
  const candidate = await getCandidateScreenings(candidateEmail);
  postMessageToExtension({
    iconText: `View ${candidate.screenerSessions.length} screeners for this candidate!`,
    pageWidth: "candidate",
    type: ExtensionAcceptedMessageTypes.AdminStateChange,
  });
};

// send dom to backend to get page information
const sendPageData = async ({
  companyId,
  dom,
}: {
  companyId: string;
  dom: string;
}) => {
  const pageInformation = await getPageInformation(companyId, dom);

  if (pageInformation.page === "job") {
    sendJobPageData(pageInformation);
  } else if (pageInformation.page === "candidate") {
    sendCandidatePageData(pageInformation);
  } else {
    postMessageToExtension({
      iconText: null,
      pageWidth: "full",
      type: ExtensionAcceptedMessageTypes.AdminStateChange,
    });
  }
  return pageInformation;
};

const closeExtension = () => {
  postMessageToExtension({
    type: ExtensionAcceptedMessageTypes.SetExtensionOpenState,
    open: false,
  });
};

export { initializeAdmin, sendPageData, closeExtension };

import { usePrevious } from "@mantine/hooks";
import type { CoreContext, CoreOptions } from "@segment/analytics-core";
import { useCallback, useEffect } from "react";

import { useSegmentContext } from "@/context/segment.context";
import { useUserContext } from "@/context/user.context";
import type { User } from "@/types/user";
import { Nullable } from "@/types/utils";

// Re-declaring these here rather than importing because segment/next awful typing of third party libs.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventProperties = Record<string, any>;
type Callback = (ctx: CoreContext) => Promise<unknown> | unknown;

const useDefaultUserProperties = () => {
  const { user } = useUserContext();

  if (!user) {
    return {};
  }

  return {
    atsSystem: user.company.atsSystem,
    company_id: user.company.id,
    user_id: user.id,
  };
};

export const useIdentifyUserAnalyticsEffect = (user: Nullable<User>) => {
  const { analytics } = useSegmentContext();
  const previousUser = usePrevious(user);

  useEffect(() => {
    if (user && user.id !== previousUser?.id) {
      analytics.identify(user.id, {
        company_id: user.company.id,
        email: user.email,
      });
    }
  }, [analytics, previousUser, user]);
};

export const useTrackAnalytics = (additionalDefaults: EventProperties = {}) => {
  const { analytics } = useSegmentContext();
  const defaultUserProperties = useDefaultUserProperties();

  return (
    eventName: string,
    properties: EventProperties = {},
    options?: CoreOptions,
    callback?: Callback,
  ) => {
    analytics.track(
      eventName,
      { ...defaultUserProperties, ...additionalDefaults, ...properties },
      options,
      callback,
    );
  };
};

export const useFilterAnalytics = <T>(filter_name: string) => {
  const track = useTrackAnalytics();

  return useCallback(
    (filter_value: T) => track("Filter Clicked", { filter_name, filter_value }),
    [filter_name, track],
  );
};
